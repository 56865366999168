import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Programs.css";
import { useTranslation } from "react-i18next";
import { isActive } from "../Utils/Utils.js";

function ProgramCard(props) {
  const { t, i18n } = useTranslation();
  const [showFeedback, setShowFeedback] = useState(false);

  let status_program = isActive(
    props.program.created_at,
    props.program.duration
  );
  let status;
  let variant;

  if (status_program === true) {
    status = "active";
    variant = "success";
  } else {
    status = "inactive";
    variant = "danger";
  }

  let program_length =
    (props.program.sessions.length /
      (props.program.frequency * props.program.duration)) *
    100;

  let sorted_program_sessions = props.program.sessions
    ?.map((obj) => {
      return { ...obj, created_at: new Date(obj.created_at) };
    })
    .sort((a, b) => b.created_at - a.created_at);

  const toggleFeedback = () => {
    setShowFeedback(!showFeedback);
  };

  return (
    <Row className={"program-overview-row-" + status} key={props.id}>
      <Row>
        <Col xs={12} className="mobile-title">
          <h3>
            <Link
              to={`/programs/${props.program.id}`}
              style={{ color: "black" }}
            >
              {props.program.name}
            </Link>
          </h3>
        </Col>
      </Row>
      <Col xl={2} lg={2} md={4} className="workout-thumbnail">
        <center>
          <Link to={`/programs/${props.program.id}`}>
            <img
              src="/images/image_placeholder.png"
              alt="sophyapp logo"
              className="program-thumbnail"
            />
          </Link>
        </center>
      </Col>
      <Col xl={5} lg={5} md={4} className="d-none d-md-block">
        <Row>
          <Col md={12}>
            <h3>
              <Link
                to={`/programs/${props.program.id}`}
                style={{ color: "black" }}
              >
                {props.program.name}
              </Link>
            </h3>
          </Col>
        </Row>
        <Row></Row>
        <Row md={12}>
          <Col md={12}>
            <Link to={`/programs/${props.program.id}`} key={props.program.id}>
              {t("program.details")}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={"program-progress-" + status}>
            {t("program.program-progress")} {program_length.toFixed(2)} %
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={8} xl={5}>
            <ProgressBar variant={variant} now={program_length} />
          </Col>
        </Row>
      </Col>
      <Col xl={5} lg={5} md={4} className="d-none d-md-block">
        <Row>
          <Col md={12} className={"feedback-title-" + status}>
            {t("feedback.last-feedback")}
          </Col>
        </Row>
        <Row>
          {sorted_program_sessions?.map((session, key) => {
            let feedback_text = session.program_feedback
              ? session.program_feedback
              : "";
            if (key > 4) {
              return null;
            }
            return (
              <Col md={12} className="feedback-details">
                {new Date(session.created_at).toLocaleDateString("de-DE") +
                  " - (" +
                  session.pain_level +
                  ") " +
                  feedback_text}
              </Col>
            );
          })}
        </Row>
      </Col>
      <Row className={"mobile-progress-" + status}>
        <Col xs={12}>
          {t("program.program-progress")} {program_length.toFixed(2)} %
        </Col>
      </Row>
      <Row className="mobile-progress-bar">
        <Col xs={12}>
          <ProgressBar variant={variant} now={program_length} />
        </Col>
      </Row>
      {sorted_program_sessions?.length > 0 ? (
        <Row className={"mobile-feedback-title-" + status}>
          {/* <Col xs={12}>{t("feedback.last-feedback")}</Col> */}
          <Col xs={12}>
            <div
              onClick={toggleFeedback}
              style={{ cursor: "pointer", color: "grey" }}
            >
              {showFeedback
                ? t("feedback.hide-feedback")
                : t("feedback.show-feedback")}
            </div>
          </Col>
        </Row>
      ) : null}
      {showFeedback && (
        <Row
          className="mobile-feedback-details"
          style={{
            maxHeight: "50px",
            overflowY: "auto",
            transition: "max-height 0.3s ease",
          }}
        >
          {sorted_program_sessions?.map((session, key) => {
            let feedback_text = session.program_feedback
              ? session.program_feedback
              : "";
            if (key > 2) {
              return null;
            }
            return (
              <Col xs={12} key={key}>
                {new Date(session.created_at).toLocaleDateString("de-DE") +
                  " - (" +
                  session.pain_level +
                  ") " +
                  feedback_text}
              </Col>
            );
          })}
        </Row>
      )}
      <Row className="mobile-details">
        <Col xs={12}>
          <Link to={`/programs/${props.program.id}`}>Details anzeigen</Link>
        </Col>
      </Row>
    </Row>
  );
}

export default ProgramCard;
