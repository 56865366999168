import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import Loader from "../Utils/Loader";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

export default function Preferences() {
  let content = null;
  const { t } = useTranslation();
  const [physio, setPhysio] = useState();
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/v2/${getAccessToken()}/patients/my_physio`;
  const headers = {
    "Content-Type": "multipart-form-data",
    Accept: "application/json",
  };

  const [request, setRequest] = useState({
    loading: false,
    data: null,
    error: false,
  });
  useEffect(() => {
    setRequest({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, { headers: headers })
      .then((response) => {
        setRequest({
          loading: false,
          data: response.data,
          error: false,
        });
        setPhysio(response.data);
      })
      .catch(() => {
        setRequest({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [url]);

  if (request.error) {
    content = (
      <div>
        Physiodaten nicht gefunden. Entweder Sie besitzen noch kein aktives
        Programm oder sind noch nicht mit einem Physiotherapeuten verknüpft!
      </div>
    );
  }

  if (request.loading) {
    content = <Loader />;
  }

  if (request.data) {
    content = (
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={8}>
            <div className="text-center">
              <img
                src="./images/avatar_placeholder.png" // Replace with the actual image path
                alt="Therapist"
                className="rounded-circle mb-4"
                style={{ width: "150px", height: "150px" }}
              />
              <h3>{physio.clinic_name}</h3>
              <p className="mt-3">{physio.street}</p>
              <p className="mt-3">
                {physio.zip} {physio.city}
              </p>
              <p className="mt-3">{physio.country}</p>
              <p className="mt-3">{physio.phone}</p>
              <p className="mt-3">{physio.email}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  return <React.Fragment>{content}</React.Fragment>;
}
