import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./Exercise.css";
import parse from "html-react-parser";

function ExerciseCard(props) {
  console.log(props);
  return (
    <Container className="exercise-card mobile-width">
      <Row>
        <Col className="exercise-title">
          {props.exercise.source.mapping_sources_translation[0]?.name
            ? props.exercise.source.mapping_sources_translation[0].name
            : parse(props.exercise.name)}
        </Col>
      </Row>
      <Row>
        <Col className="body-region-title">
          {props.exercise.source.body_region}
        </Col>
      </Row>
      <Row>
        <Col className="exercise-video-player">
          <center>
            {/* <ReactPlayer url="{props.exercise.source.video_url}" light={props.exercise.source.thumb_url} /> */}
            <video
              controls
              loop
              src={props.exercise.source.video_url}
              poster={props.exercise.source.thumb_url}
              className="video-content"
            >
              Sorry, your browser doesn't support embedded videos, but don't
              worry, you can <a href="/">download it</a>
              and watch it with your favorite video player!
            </video>
          </center>
        </Col>
      </Row>
      <Row>
        <Col className="prescription">{props.exercise.prescription}</Col>
      </Row>
      <Row>
        <Col xs={12} className="exercise-description">
          <ul>
            {props.exercise.source.mapping_sources_translation[0]?.steps
              ? props.exercise.source.mapping_sources_translation[0].steps
                  .split("<br />")
                  .map((step, index) => (
                    //find better solution for parsing steps due to li elements
                    <li key={index}>{parse(step)}</li>
                  ))
              : props.exercise.source.steps.map((step, index) => (
                  //find better solution for parsing steps due to li elements
                  <li key={index}>{parse(step)}</li>
                ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col className="exercise-note">{props.exercise.note}</Col>
      </Row>
    </Container>
  );
}

export default ExerciseCard;
