import React, { createContext, useState, useEffect } from "react";
import useToken from "../pages/Utils/useToken";

export const AuthContext = createContext();

// Inside AuthProvider
export const AuthProvider = ({ children }) => {
  console.log("AuthProvider");
  const { token, setToken } = useToken();
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};
