import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getAccessToken } from "../Utils/Utils.js";
import axios from "axios";
import Loader from "../Utils/Loader";
import "./Preferences.css";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

function checkPasswordPolicy(pw) {
  if (pw.length > 6) {
    return true;
  } else {
    return false;
  }
}

export default function Preferences() {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    `/de/v2/${getAccessToken()}/patient`;
  const headers = {
    "Content-Type": "multipart-form-data",
    Accept: "application/json",
  };

  const [request, setRequest] = useState({
    loading: false,
    data: null,
    error: false,
  });
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [language, setLanguage] = useState();
  const [languageAbb, setLanguageAbb] = useState();
  const { t, i18n } = useTranslation();

  // toDO make abbreviations/language handling much simpler
  const handleLanguageChange = (lang, lg, name) => {
    let abbrev;

    if (lang === "English") {
      abbrev = "en";
      setLanguage("English");
    } else if (lang === "Italian") {
      abbrev = "it";
      setLanguage("Italian");
    } else {
      abbrev = "de";
      setLanguage("Deutsch");
    }
    i18n.changeLanguage(abbrev);
    setLanguageAbb(abbrev);
  };

  const handleSubmit = (e) => {
    var params = new FormData();
    params.append("patient[name]", name);
    params.append("patient[language]", languageAbb);
    if (password != null) {
      if (checkPasswordPolicy(password)) {
        params.append("password", password);
      }
    }
    axios
      .put(url + ".json", params)
      .then(function (response) {})
      .catch(function (error) {});
    e.preventDefault();
  };

  let content = null;

  useEffect(() => {
    setRequest({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, { headers: headers })
      .then((response) => {
        setRequest({
          loading: false,
          data: response.data,
          error: false,
        });
        setName(response.data.name);
        setEmail(response.data.email);
        if (response.data.language === "en") {
          setLanguage("English");
        } else if (response.data.language === "it") {
          setLanguage("Italian");
        } else {
          setLanguage("Deutsch");
        }
      })
      .catch(() => {
        setRequest({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [url]);

  if (request.error) {
    content = <div>Daten nicht gefunden. Bitte aktualisiere die Seite.</div>;
  }

  if (request.loading) {
    content = <Loader />;
  }

  if (request.data) {
    content = (
      <Container fluid className="h-100">
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            Name
          </Col>
          <Col md={5}>
            <input
              type="text"
              name="name"
              className="profile-input-field"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            E-Mail
          </Col>
          <Col md={5}>
            <input
              type="text"
              name="email"
              className="profile-input-field"
              readOnly
              value={email}
            />
          </Col>
        </Row>
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            {t("password.change")}
          </Col>
          <Col md={5}>
            <input
              name="password"
              className="profile-input-field"
              type="password"
              value={password}
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            {t("language")}
          </Col>
          <Col md={5}>
            {/* <input
              type="text"
              name="language"
              className="profile-input-field"
              value="Deutsch"
              readOnly
            /> */}
            <select
              name="language"
              value={language}
              onChange={(event) =>
                handleLanguageChange(
                  event.target.value,
                  event.target.id,
                  event.target.name
                )
              }
              className="select-box"
            >
              <option id="de" name="de">
                German
              </option>
              <option id="en">English</option>
              <option id="it"> Italian</option>
            </select>
          </Col>
        </Row>
        <Row className="h-100 table-row">
          <Col md={3} className="table-column-title">
            <button
              type="submit"
              className="login-button"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Speichern
            </button>
          </Col>
        </Row>
      </Container>
    );
  }

  return <React.Fragment>{content}</React.Fragment>;
}
