// Header.js
import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BrandingContext } from "../pages/Contexts/Context";

const Header = () => {
  const { applicationImagePath } = useContext(BrandingContext);
  const logout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };

  return (
    <Row className="app-header no-padding">
      <Col
        xxl={1}
        xl={2}
        lg={2}
        md={3}
        sm={3}
        xs={7}
        className="app-header-image"
      >
        <NavLink to="/" activeClassName="active">
          <img
            src={applicationImagePath}
            alt="Logo"
            className="app-header-image-class"
          />
        </NavLink>
      </Col>
      <Col
        xs={{ span: 5, offset: 2 }}
        md={{ span: 3, offset: 4 }}
        lg={{ span: 2, offset: 7 }}
        xl={{ span: 2, offset: 7 }}
        xxl={{ span: 3, offset: 7 }}
        className="searchbar d-none d-sm-block"
      >
        {/* Placeholder for potential search feature or name display */}
      </Col>
      <Col
        xs={4}
        sm={2}
        md={2}
        lg={1}
        className="header-icons offset-1 offset-sm-0"
      >
        <a
          href="https://hoferdigital.atlassian.net/wiki/spaces/SS/pages/2949447681/Wiki+f+r+Patienten+Athleten+und+Klienten"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/images/help_icon.svg"
            alt="Documentation"
            className="spacer header-icon help-icon"
          />
        </a>
        <NavLink to="/preferences" activeclassname="active">
          <img
            src="/images/profile_icon.svg"
            alt="Profile"
            style={{ cursor: "pointer" }}
            title="Einstellungen"
            className="header-icon profile-icon"
          />
        </NavLink>
        <img
          src="/images/logout-24.png"
          alt="Profile"
          style={{ cursor: "pointer" }}
          title="Abmelden"
          onClick={() => logout()}
          className="header-icon exit-icon"
        />
      </Col>
    </Row>
  );
};

export default Header;
