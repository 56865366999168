import React from "react";
import { Navigate } from "react-router-dom";
import Menu from "./views/Menu";
import Profile from "./pages/Preferences/Profile";
import About from "./pages/Preferences/About";
import Programs from "./pages/Programs/Programs";
import Program from "./pages/Programs/Program";
import MyPhysio from "./pages/Physio/MyPhysio";
import Sidebar from "./pages/Preferences/Sidebar";
import Version from "./pages/Preferences/Version";
import Materials from "./pages/Materials/Materials";

const routes = [
  {
    path: "/",
    element: (
      <>
        <Navigate to="/dashboard" />
        <Menu content={<Programs />} />
      </>
    ),
    isProtected: true,
  },
  {
    path: "/dashboard",
    element: <Menu content={<Programs />} />,
    isProtected: true,
  },
  {
    path: "/programs",
    element: <Menu content={<Programs />} />,
    isProtected: true,
  },
  {
    path: "/programs/:id",
    element: <Menu content={<Program />} />,
    isProtected: true,
  },
  {
    path: "/myphysio",
    element: <Menu content={<MyPhysio />} />,
    isProtected: true,
  },
  {
    path: "/preferences",
    element: (
      <>
        <Navigate to="/preferences/profile" />
        <Menu
          content={<Sidebar contentdisplay={<Profile />} title="Profile" />}
        />
      </>
    ),
    isProtected: true,
  },
  {
    path: "/preferences/profile",
    element: (
      <Menu
        content={<Sidebar contentdisplay={<Profile />} title="Profile" />}
      />
    ),
    isProtected: true,
  },
  {
    path: "/preferences/version",
    element: (
      <Menu
        content={<Sidebar contentdisplay={<Version />} title="Version" />}
      />
    ),
    isProtected: true,
  },
  {
    path: "/preferences/about",
    element: (
      <Menu
        content={<Sidebar contentdisplay={<About />} title="Über sophyapp" />}
      />
    ),
    isProtected: true,
  },
  {
    path: "/materials",
    element: <Menu content={<Materials />} />,
    isProtected: true,
  },
  {
    path: "*",
    element: (
      <>
        <Navigate to="/dashboard" />
        <Menu content={<Programs />} />
      </>
    ),
    isProtected: true,
  },
];

export default routes;
