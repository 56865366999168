import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./Programs.css";
import ProgramCard from "./ProgramCard";
import { getAccessToken } from "../Utils/Utils.js";
import Loader from "../Utils/Loader";
import axios from "axios";
import ProfileCard from "../Profile/ProfileCard";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
function Programs() {
  const url =
    process.env.REACT_APP_SOPHYAPP_API_URL +
    "/de/v2/" +
    getAccessToken() +
    "/programs";
  const [programs, setPrograms] = useState({
    loading: false,
    data: null,
    error: false,
  });

  let content = null;

  useEffect(() => {
    setPrograms({
      loading: true,
      data: null,
      error: false,
    });
    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        setPrograms({
          loading: false,
          data: response.data,
          error: false,
        });
      })
      .catch(() => {
        setPrograms({
          loading: false,
          data: null,
          error: true,
        });
      });
  }, [url]);

  if (programs.error) {
    content = <p>Ein Fehler ist aufgetreten. Bitte versuche es noch einmal</p>;
  }

  if (programs.loading) {
    content = <Loader></Loader>;
  }

  if (programs.data) {
    // outsource sort func to utils
    let sorted_programs = programs.data
      ?.map((obj) => {
        return { ...obj, created_at: new Date(obj.created_at) };
      })
      .sort((a, b) => b.created_at - a.created_at);
    content = (
      <Container fluid className="program-overview-container">
        <Row className="profile-card-mobile">
          <ProfileCard />
        </Row>
        {sorted_programs?.map((program, key) => (
          <ProgramCard program={program} id={key} key={key} />
        ))}
        <div className="fake-program-card"></div>
      </Container>
    );
  }

  return <div>{content}</div>;
}

export default Programs;
