import { useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { Navigate } from "react-router-dom";
import useToken from "../pages/Utils/useToken";

function ProtectedRoute({ children }) {
  const { token } = useToken();

  return token ? children : null;
}

export default ProtectedRoute;
