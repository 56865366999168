import React, { useEffect, useState } from "react";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

interface FilePreviewProps {
  fileUrl: string;
  file: string;
}

const FilePreview = ({ fileUrl, file }: FilePreviewProps) => {
  const [fileType, setFileType] = useState<string | null>(null);

  useEffect(() => {
    console.log("FileUrl: ", fileUrl);
    const determineFileType = () => {
      const extension = file.split(".").pop();
      if (extension) {
        setFileType(extension.toLowerCase());
      } else {
        setFileType(null);
      }
    };

    determineFileType();
  }, [file, fileUrl]);

  if (!fileType) {
    return <div>Loading...</div>;
  }

  if (fileType === "pdf") {
    return <PictureAsPdfIcon style={{ fontSize: 100 }} />;
  }

  if (["jpg", "jpeg", "png", "gif", "bmp"].includes(fileType)) {
    return (
      <img
        src={fileUrl}
        alt="Preview"
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      />
    );
  }

  if (["mp4", "avi", "mov", "wmv", "flv", "mkv"].includes(fileType)) {
    return <VideoLibraryIcon style={{ fontSize: 100 }} />;
  }

  return <InsertDriveFileIcon style={{ fontSize: 100 }} />;
};

export default FilePreview;
