import React, { Suspense, useState, useEffect } from "react";
import "./App.css";
import Menu from "./views/Menu";
// import Dashboard from "./Components/Dashboard/Dashboard";
// import Preferences from "./Components/Preferences/Preferences";
import Login from "./pages/Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useToken from "./pages/Utils/useToken";
import { withTranslation } from "react-i18next";
import { BrandingContext } from "./pages/Contexts/Context";
import routes from "./routes";
import ProtectedRoute from "./utils/ProtectedRoute";
import { AuthProvider } from "./utils/AuthProvider";
import { getBrandingSettings } from "./config/brandingConfig";

function App() {
  const hostname = window.location.hostname;
  const brand = getBrandingSettings(hostname);
  const [applicationName, setApplicationName] = useState(brand.name);
  const [applicationImagePath, setApplicationImagePath] = useState(
    brand.imagePath
  );
  const { token, setToken } = useToken();

  // outsource and manage in index.html
  useEffect(() => {
    // const hostname = window.location.hostname;
    // if (hostname.endsWith("medfit.ch")) {
    //   import("./styles/medfit.css").then(() => {});
    //   setApplicationName("medfit");
    //   setApplicationImagePath("/images/ci/medfit/medfit1.jpg");
    //   var link = document.querySelector("link[rel~='icon']");
    //   if (!link) {
    //     link = document.createElement("link");
    //     link.rel = "icon";
    //     document.head.appendChild(link);
    //   }
    //   link.href = "/images/ci/medfit/faviconV2.ico";
    //   document.title = "Fit für Morgen!";
    // } else if (hostname.endsWith("medaix-exercise.de")) {
    //   import("./styles/medaix.css").then(() => {});
    //   setApplicationName("medaix");
    //   setApplicationImagePath("/images/ci/medaix/medaix_logo.png");
    //   document.title = "MedAix Exercise";
    // } else {
    //   import("./styles/variables.css").then(() => {});
    // }
    const cssPath = brand.css;
    import(`${cssPath}`).then(() => {});
    document.title = brand.title;

    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = brand.icon;
  }, []);

  if (!token) {
    console.log("App.js: token not set");
    return (
      <Login
        setToken={setToken}
        application={applicationName}
        imagePath={applicationImagePath}
      />
    );
  }
  return (
    <BrandingContext.Provider value={{ applicationName, applicationImagePath }}>
      <Suspense fallback="loading">
        <AuthProvider>
          <div className="main_background">
            <BrowserRouter>
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      route.isProtected ? (
                        <ProtectedRoute>{route.element}</ProtectedRoute>
                      ) : (
                        route.element
                      )
                    }
                  />
                ))}
              </Routes>
            </BrowserRouter>
          </div>
        </AuthProvider>
      </Suspense>
    </BrandingContext.Provider>
  );
}

export default withTranslation()(App);
